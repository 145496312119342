import React from 'react'
import { Search } from 'react-spotify-api'
import { RecommendationsTable } from './RecommendationsTable'
import { SpotifyIconSmall } from './SpotifyIconSmall'
import { SpotifyLogo } from './SpotifyLogo'
import { BrowseRecommendations } from 'react-spotify-api'
import { ReactComponent as AppLogo } from '../img/playlist-mix-logo-light.svg';
import EditIcon from '../img/pen.png';
import axios from 'axios';
import { Popup } from './Popup'
import { SettingsPopup } from './SettingsPopup'

export const Main = (props) => {
    const spotifyApi = "https://api.spotify.com/v1"
    const [query, setQuery] = React.useState("")
    const [playlistName, setPlaylistName] = React.useState("Playlist Name")
    const playlistNameRef = React.useRef(null);
    const [playlistUrl, setPlaylistUrl] = React.useState("")
    const playlistDesc = "Playlist created with PlaylistMix"
    const [artists, setArtists] = React.useState([])
    const [tracks, setTracks] = React.useState([])
    const [genres, setGenres] = React.useState([])
    const [tags, setTags] = React.useState([])
    const [limitReached, setLimitReached] = React.useState(false)
    const [playlistVis, setPlaylistVis] = React.useState(false)
    const [playlistReady, setPlaylistReady] = React.useState(false)
    const [settingsPopupVis, setSettingsPopupVis] = React.useState(false)
    const [playlistCreatedVis, setPlaylistCreatedVis] = React.useState(false)
    const [playlistTracks, setPlaylistTracks] = React.useState([])

    const closePopup = () => {
        setPlaylistCreatedVis(() => false)
    };

    const closeSettingsPopup = () => {
        setSettingsPopupVis(() => false)
    };

    const setPlaylistNameRef = () => {
        playlistNameRef.current.focus();
      };
    const handleQueryChange = (e) => {
        setQuery(e.target.value)
    }
    const handlePlaylistNameChange = (e) => {
        setPlaylistName(e.target.value)
    }
    const playlistSwitch = (e) => {
        setPlaylistVis(false)
        setPlaylistReady(false)
        setTimeout(() => {
            buildRecommendationQuery()
            setPlaylistVis(true);
          }, 500);
    }
    const savePlaylist = (e) => {
        
        axios.post(spotifyApi + '/users/' + props.userId + '/playlists',
        {"name": playlistName,"description": playlistDesc,"public": true},
        {headers: {'Authorization': 'Bearer ' + props.token},})
          .then(function (response) {
            setPlaylistUrl(response.data.external_urls.spotify)
            let uris = []
            for (let track of playlistTracks) {
                uris.push(track.uri)
            }
            axios.post(spotifyApi + '/playlists/' + response.data.id + '/tracks',
            {"uris": uris},
            {headers: {'Authorization': 'Bearer ' + props.token},})
              .then(function (response) {
                setPlaylistCreatedVis(true)
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const handleUpdate = (contentType, content, mutationType) => {
        if (tags.length < 5 || mutationType === "delete") {
            if (contentType === 'track') {
                if (mutationType === "add") { setTracks(prevState => [...prevState, content]) }
                else if (mutationType === "delete") {setTracks(prevState => (prevState.filter(track => track.name !== content.name)))}

            } else if (contentType === 'artist') {
                if (mutationType === "add") { setArtists(prevState => [...prevState, content]) }
                else if (mutationType === "delete") {setArtists(prevState => (prevState.filter(artist => artist.name !== content.name)))}

            } else if (contentType === 'genre') {
                if (mutationType === "add") { setGenres(prevState => [...prevState, content]) }
                else if (mutationType === "delete") {setGenres(prevState => (prevState.filter(genre => genre !== content.name)));}
    
            }
            setQuery("")
        }
    }

    React.useEffect(() => {
        setTags([...artists.map(a => ({"name" : a.name, "type" : "artist", "key" : a.id, "url" : a.external_urls.spotify})), ...tracks.map(a => ({"name" : a.name, "type" : "track", "key" : a.id, "url" : a.external_urls.spotify})), ...genres.map(a => ({"name" : a, "type" : "genre", "key" : a}))])
      }, [artists, tracks, genres]);

    React.useEffect(() => {
        if (tags.length === 5) {
            setLimitReached(true)
        } else {
            setLimitReached(false)
        }
      }, [tags]);

    const [limit, setLimit] = React.useState(15)
    const [target_liveness, setTarget_liveness] = React.useState("")
    const handleTargetLiveness = (e) => {
        setTarget_liveness(e.target.value)
    }
    const [target_popularity, setTarget_popularity] = React.useState("")
    const handleTargetPopularity = (e) => {
        setTarget_popularity(e.target.value)
    }
    const [recommendationQuery, setRecommendationQuery] = React.useState({})
    const buildRecommendationQuery = (e) => {
        let recommendationObj = {}
        if (tracks) { recommendationObj.seed_tracks = tracks.map(track => track.id).toString() }
        if (artists) { recommendationObj.seed_artists = artists.map(track => track.id).toString() }
        if (genres) { recommendationObj.seed_genres = genres.map(genre => genre).toString() }
        recommendationObj.limit = limit
        if (target_liveness) { recommendationObj.target_liveness = Number(target_liveness) }
        if (target_popularity) { recommendationObj.target_popularity = Number(target_popularity * 10) }
        setRecommendationQuery(recommendationObj)
    }
    const handleGeneratedPlaylist = (data) => {
        setPlaylistTracks(data.tracks)
        setPlaylistReady(true)
    }

  return (
    <div className="flex flex-col md:flex-row h-screen">
        <div className={`md:flex md:basis-1/4 flex-col justify-between z-10 h-full md:b-r-1 b drop-shadow-lg px-5 py-8 ${playlistVis ? 'hidden' : 'flex'}`}>
            <div className='queryTop'>
            <AppLogo className='mx-auto md:mx-0 w-4/5 pb-3' />
            <div className='relative'>
            <input className={`w-full bg-transparent rounded-full border-2 border-white text-white py-2 px-4 text-lg mb-2  ${limitReached ? ' placeholder:text-red-400 pointer-events-none' : ''}`} type='text'
            placeholder={ limitReached ? 'Limit of 5 seeds reached' : 'Seed up to 5 artists or tracks'}
            value={query}
            onChange={handleQueryChange} 
            />
                <Search className="z-10" query={query} track artist>
                    {(data) =>
                        data.data ? (
                            <div className={`absolute top-12 bg-zinc-800 rounded-xl mt-3 w-full md:w-[50vw] text-white dropdown flex flex-col md:flex-row justify-between z-10 ${query.length === 0 ? 'hidden' : ''}`}>
                                <div className='w-full md:w-1/3 py-3 px-5'>
                                    <div className='py-1 flex justify-between border-b border-white items-center'>
                                    <p className='text-lg  text-green-400'>Tracks</p>
                                    <div className="block md:hidden"><SpotifyIconSmall/></div>
                                    
                                    </div>
                                    <ul>
                                        {data.data.tracks.items.slice(0,5).map((track, index) => (
                                            <li className={`search-item-${index} line-clamp-1 cursor-pointer py-1 border-b border-white hover:bg-zinc-600 cursor-pointer flex justify-between  ${tags.find(el => el.key === track.id) ? "pointer-events-none cursor-not-allowed" : ""} `} onClick={() => handleUpdate('track', track, "add")} key={track.id}>{track.name} {tags.find(el => el.key === track.id) ?  <span className='bg-green-400 h-6 w-6 rounded-full text-center px-1'>&#x2713;</span> : ''}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='w-full md:w-1/3 py-3 px-5'>
                                    <div className='py-1 flex justify-between border-b border-white items-center'>
                                    <p className='text-lg  text-green-400'>Artists</p>
                                    </div>
                                    <ul>
                                        {data.data.artists.items.slice(0,5).map((artist, index) => (
                                            <li className={`search-item-${index} line-clamp-1 cursor-pointer py-1 border-b border-white hover:bg-zinc-600 cursor-pointer flex justify-between ${tags.find(el => el.key === artist.id) ? "pointer-events-none cursor-not-allowed" : ""}`} onClick={() => handleUpdate('artist', artist, "add")} key={artist.id}>{artist.name} {tags.find(el => el.key === artist.id) ? <span className='bg-green-400 h-6 w-6 rounded-full px-1 text-center'>&#x2713;</span> : ''}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='w-full md:w-1/3 py-3 px-5'>
                                    <div className='py-1 flex justify-between border-b border-white items-center'>
                                    <p className='text-lg  text-green-400'>Genres</p>
                                    <div className="hidden md:block"><SpotifyIconSmall /></div>
                                    
                                    </div>
                                    <ul>
                                        {props.spotifyGenres.filter((el) => el.toLowerCase().includes(query.toLowerCase())).slice(0,5).map((genre, index) => (
                                            <li className={`search-item-${index} line-clamp-1 cursor-pointer py-1 border-b border-white hover:bg-zinc-600 cursor-pointer capitalize flex justify-between ${tags.find(el => el.key === genre) ? "pointer-events-none cursor-not-allowed" : ""}`} onClick={() => handleUpdate('genre', genre, "add")} key={genre}>{genre} {tags.find(el => el.key === genre) ? <span className='bg-green-400 h-6 w-6 rounded-full px-1 text-center'>&#x2713;</span> : ''}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : null
                    }
                </Search>
                </div>
                <div className='flex flex-row flex-wrap'>
                {
                    tags ? (
                    tags.map(tag => (
                        <div className='flex flex-row px-2 py-2 text-white' key={tag.name}>
                            <a href={tag.url ? tag.url : null} target="_blank" rel="noreferrer"><div className='line-clamp-1 capitalize rounded-l-full bg-green-400 px-3 border-r border-white py-1 pl-4 pr-3  '>{tag.name}</div></a>
                            <button title={tag.name} onClick={() => handleUpdate(tag.type, tag, "delete")} className='rounded-r-full bg-green-400 px-3'><p className='rotate-45'>+</p></button>
                        </div>
                    ))) : null 
                    }
            </div>
            </div>
            <div className='queryBottom border-t border-white pt-5'>
                <form className='flex flex-col justify-evenly text-white items-start'>
                    <div className='flex flex-row justify-between w-full mb-3 items-center'>
                        <label>Songs (1-99)</label><br/><br/>
                        <div id="limitNumber" className='flex flex-row border-solid border border-white bg-transparent rounded-full py-1 px-3 justify-between'>
                            <button type="button" onClick={limit > 1 ? () => setLimit(limit - 1) : null} className='white'>-</button>
                            <p className=''>{limit}</p>
                            <button type="button" onClick={limit < 99 ? () => setLimit(limit + 1) : null} className='white'>+</button>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between w-full mb-3 items-center'>
                        <label hmtlFor="targetLiveness">Target Liveness (0-10)</label><br/><br/>
                        <select
                        value={target_liveness}
                        onChange={handleTargetLiveness}
                        name="targetLiveness"
                        className='border-solid border border-white bg-transparent rounded-full py-2 px-3 text-center'>
                            <option value="">-</option>
                            <option value="0">0</option>
                            <option value="0.1">1</option>
                            <option value="0.2">2</option>
                            <option value="0.3">3</option>
                            <option value="0.4">4</option>
                            <option value="0.5">5</option>
                            <option value="0.6">6</option>
                            <option value="0.7">7</option>
                            <option value="0.8">8</option>
                            <option value="0.9">9</option>
                            <option value="1">10</option>
                        </select>
                    </div>
                    <div className='flex flex-row justify-between w-full mb-3 items-center'>
                        <label htmlFor={"targetPopularity"}>Target Popularity (0-10)</label><br/><br/>
                        <select
                        value={target_popularity}
                        onChange={handleTargetPopularity}
                        name="targetPopularity"
                        className='border-solid border border-white bg-transparent rounded-full py-2 px-3 text-center'>
                            <option value="">-</option>
                            <option value="0">0</option>
                            <option value="0.1">1</option>
                            <option value="0.2">2</option>
                            <option value="0.3">3</option>
                            <option value="0.4">4</option>
                            <option value="0.5">5</option>
                            <option value="0.6">6</option>
                            <option value="0.7">7</option>
                            <option value="0.8">8</option>
                            <option value="0.9">9</option>
                            <option value="1">10</option>
                        </select>
                    </div>
                </form>
            <div className='createButton basis-12 mt-5 flex justify-between'>
                <button className='rounded-full text-white bg-green-400 py-2 px-4' onClick={playlistSwitch}>Make Playlist</button>
                <button className='rounded-full text-white bg-green-400 py-2 px-3' onClick={() => setSettingsPopupVis(true)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></button>
            </div>
            </div>

        </div>
        <div className={`md:flex playList px-5 py-8 md:basis-3/4 h-full flex-col bg-[radial-gradient(circle_at_right_top,_var(--tw-gradient-stops))] from-green-400 to-[#18181b] ${playlistVis ? 'flex' : 'hidden'} `}>
            <div id="playlistHeader" className='basis-1/6 md:basis-1/12 flex flex-row justify-start'>
                {   !playlistVis &&
                    <h3 className='text-white text-4xl'>&#8592; Build Playlist</h3>
                }
                {   playlistVis &&
                    <div className='flex flex-row w-full text-white'>
                        <div className='basis-5/6 flex items-baseline'>
                            <input className="text-4xl bg-transparent editPlaylistName" type='text'
                            value={playlistName}
                            onChange={handlePlaylistNameChange}
                            size={playlistName.length}
                            ref={playlistNameRef}
                            />
                            <img alt="edit" className='h-6 invert cursor-pointer' src={EditIcon} onClick={setPlaylistNameRef}></img>
                        </div>
                        <div className='flex md:hidden basis-1/6 justify-center'>
                            <p onClick={() => setPlaylistVis(false)} className='inline-block text-4xl rotate-45'>+</p>
                        </div>
                    </div>
                }
            </div>
            { playlistVis &&
            <div className='basis-3/4 md:basis-3/4 overflow-scroll flex flex-col justify-start'>
                <div className={`playlist transition-all duration-1000 overflow-auto mt-0 pt-0 " ${playlistReady ? "opacity-100 scale-100" : "opacity-0 scale-50"}`}>
                    <BrowseRecommendations
                        options={recommendationQuery}>
                        {(data) => (
                            data.data ? (
                                handleGeneratedPlaylist(data.data)
                            ) : null
                        )
                        }
                        
                    </BrowseRecommendations>
                
                    {   playlistTracks &&
                    <RecommendationsTable playlistTracks={playlistTracks}/>
                    }
                </div>
            </div>
            }
            { playlistVis &&
            <div className='basis-1/6 md:basis-1/6 flex flex-col justify-end'>
                    <div className='flex flex-row justify-between items-center'>
                        <button className='rounded-full bg-green-400 py-2 px-4 text-white' onClick={savePlaylist}>Save Playlist</button>
                            <SpotifyLogo />
                    </div>
            </div>
        }
        </div>
        {   playlistCreatedVis &&
            <Popup playlistUrl={playlistUrl} playlistName={playlistName} closePopup={closePopup}/>
        }

        {   settingsPopupVis &&
            <SettingsPopup signout={props.signout} closeSettingsPopup={closeSettingsPopup}/>
        }
        </div>
  )
}