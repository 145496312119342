import React from 'react'

export const Popup = (props) => {

    const [mobileSharePoss, setMobileSharePoss] = React.useState(true)

    const mobileShare = (title, text, url) => {
        if (navigator.share) {
          navigator.share({
            'title': title,
            'text': text,
            'url': url,
          }).then(() => {
          })
          .catch(console.error);
        } else {
          setMobileSharePoss(false)
        }
    }

    return (
        <div className='popup-wrapper flex fixed w-full h-full justify-center items-center'>
            <div className='items-center px-4 py-8 bg-green-400 text-white w-5/6 md:w-1/3 rounded-lg playlist-created-popup flex flex-col relative'>
                <p onClick={() => props.closePopup()} className='text-3xl rotate-45 float-right cursor-pointer absolute right-4 top-2'>+</p>
                <p className='text-4xl text-center mb-6' href={props.playlistUrl}>Success!</p>
                <a href={props.playlistUrl} target="_blank" rel="noopener noreferrer"><button className='inline-block rounded-full border-2 border-white bg-green-400 hover:bg-green-300 py-2 px-4 text-white mb-6' type="button">View Playlist</button></a>
                <div className={`justify-center gap-4 mobile-share md:hidden ${mobileSharePoss ? 'flex' : 'hidden' } `}>
                    <p className='mr-3'>Share:</p>
                    <svg onClick={() => mobileShare(`${props.playlistName} Created by PlaylistMix`, "Spotify playlist generated by PlaylistMix", props.playlistUrl)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="fill-white bi bi-share" viewBox="0 0 16 16"><path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/></svg>
                </div>

                <div className={`gap-4 desktop-share ${mobileSharePoss ? 'hidden md:flex' : 'flex' }`}>
                    <p className='mr-3'>Share:</p>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${props.playlistUrl}`} target="_blank" rel="noreferrer"><svg className='fill-white h-5' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg></a>
                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-text={`Check out this Spotify playlist I generated with ${props.playlistName}`}><svg className='fill-white h-5' viewBox="328 355 335 276" xmlns="http://www.w3.org/2000/svg"><path d="M 630,425A 195, 195 0 0 1 331, 600A 142, 142 0 0 0 428, 570A  70,  70 0 0 1 370, 523A  70,  70 0 0 0 401, 521A  70,  70 0 0 1 344, 455A  70,  70 0 0 0 372, 460A  70,  70 0 0 1 354, 370A 195, 195 0 0 0 495, 442A  67,  67 0 0 1 611, 380A 117, 117 0 0 0 654, 363A  65,  65 0 0 1 623, 401A 117, 117 0 0 0 662, 390A  65,  65 0 0 1 630, 425Z"/></svg></a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    <svg className='h-5 fill-white' onClick={() => navigator.clipboard.writeText(props.playlistUrl.toString())} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 115.77 122.88" style={{enableBackground:" new 0 0 115.77 122.88"}}><g><path d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"/></g></svg>
                </div>
                
            </div>
        </div>
    )
}