import React from 'react'
import { SpotifyApiContext, User } from 'react-spotify-api'
import { Main } from './Components/Main'
import { SpotifyAuth, Scopes } from 'react-spotify-auth'
import 'react-spotify-auth/dist/index.css'
import axios from 'axios';
import { ReactComponent as AppLogo } from './img/playlist-mix-logo-light.svg';

const App = ({env}) => {
  const [token, setToken] = React.useState(window.localStorage.getItem('tokenExp') > Date.now() && window.localStorage.getItem('spotifyAuthToken') ? window.localStorage.getItem('spotifyAuthToken') : null )
  const spotifyApi = "https://api.spotify.com/v1"
  const [genres, setGenres] = React.useState([])

  function handleToken(token) {
      let tokenExp = Date.now() + 3600000
      window.localStorage.setItem('tokenExp', tokenExp);
      window.localStorage.setItem('spotifyAuthToken', token);
      setToken(token)
    }

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      if (error.response.data.error.message === "The access token expired") {
        console.log("expired token")
        signout()
      }
    }
    return error;
  });

  React.useEffect(()=>{
    axios.get(spotifyApi + '/recommendations/available-genre-seeds',
    {headers: {'Authorization': 'Bearer ' + token},})
      .then(function (response) {
        setGenres(response.data.genres)
      })
      .catch(function (error) {
        console.log(error);
      })
    }, [token])

    function signout () {
      window.localStorage.removeItem('spotifyAuthToken')
      window.localStorage.removeItem('tokenExp')
        window.location.href = window.location.protocol + '//' + window.location.host;
    }

  return (
    <div className='app'>
      {token ? (
        <SpotifyApiContext.Provider value={token}>
            <User>
            {(data) =>
                data.data ? (
                    <Main spotifyGenres={genres} userId={data.data.id} token={token} signout={signout}/>
                ) : null
            }
          </User>
          
        </SpotifyApiContext.Provider>
      ) : (
        // Display the login page
        <div className='login-page w-full h-full justify-center	items-center flex flex-col'>
        <AppLogo className='mx-auto md:mx-0 w-4/5 md:w-1/5 pb-3' />
        <SpotifyAuth
          redirectUri='https://playlistmix.app/' // 'http://localhost:3000/'
          clientID='43091176ba2a4471888da7fb0dd1847f' // {env.SPOTIFY_API_KEY}
          noCookie={true}
          localStorage={true}
          scopes={[Scopes.userReadPrivate, 'user-top-read', 'playlist-modify-public']}
          onAccessToken={token => handleToken(token) }
        />
        </div>
      )}
    </div>
  )
}
export default App