import React from 'react'

export const RecommendationsTable = (props) => {

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      }
  return (
                        <div className='playlistTable flex flex-col'>
                            <div className='text-white grid border-white border-b mb-2 font-bold py-2'>
                            <p className='hidden md:block'>#</p>
                            <p className='text-white'>Track</p>
                            <p className='hidden md:block'>Album</p>
                            <p className='hidden md:block'>Time</p>
                            </div>
                            {props.playlistTracks.map((track, index) => (
                                <div key={track.id} className='grid text-white' >
                                    <p className="hidden md:block" >{index + 1}</p>
                                    <div className='flex flex-row'>
                                        <div className='mr-4'>
                                            <a href={track.album.external_urls.spotify} target="_blank" rel="noreferrer" ><img className='h-10' alt={track.album.name} src={track.album.images[2].url}></img></a>
                                        </div>
                                        <div className='flex-col'>
                                            <a href={track.external_urls.spotify} className="" target="_blank" rel="noreferrer" >{track.name}</a><br />
                                            <a href={track.artists[0].external_urls.spotify} className="text-stone-400" target="_blank" rel="noreferrer" >{track.artists[0].name}</a>
                                        </div>
                                    </div>
                                    <a href={track.album.external_urls.spotify} className="hidden md:block" target="_blank" rel="noreferrer" >{track.album.name}</a>
                                    <p className='hidden md:block text-right'>{millisToMinutesAndSeconds(track.duration_ms)}</p>
                                </div>
                            ))}
                        </div>
              )
}